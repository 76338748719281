<template>
  <div class="container-fluid" style="min-height:60vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="warehouses"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-column data-field="name" cell-template="nameTemplate" />
          <template #nameTemplate="{data}">
            <div>
              <warehouse-name-form :warehouse-data="data.data" @emit-name-updated="getWarehouses" />
            </div>
          </template>
          <dx-column data-field="address" caption="Address" cell-template="addressTemplate" />
          <template #addressTemplate="{data}">
            <div>
              {{ generateAddressString(data.data.address) }}
              <p-icon role="button" name="bi-pencil-square" class="text-primary mx-half" @click.native="openAddressForm(data)" />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12">
         <!--Begin:: Create Or Update Form-->
         <dx-util-popup
          ref="warehouseFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="800"
          height="auto"
          title="Address Form"
          :on-hidden="resetForm"
          @shown="onShownAddressFormPopup"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit="handleSubmit">
            <dx-util-form ref="warehouseFormRef" :form-data="warehouseForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="warehouseData">
              <dx-util-group-item :col-count="3">
                <dx-util-item data-field="addressType" :editor-options="addressTypeOptions" editor-type="dxSelectBox" :label="{text: 'Address Type'}" />
                <dx-util-item data-field="name" :col-span="2" editor-type="dxTextBox" :editor-options="{placeholder: 'Company or real person name'}" :label="{text: 'Name'}">
                  <dx-util-required-rule message="Name is required" />
                </dx-util-item>
              </dx-util-group-item>
              <dx-util-item data-field="line1" editor-type="dxTextBox" :label="{text: 'Address Line 1'}">
                <dx-util-required-rule message="Address is required" />
              </dx-util-item>
              <dx-util-item data-field="line2" editor-type="dxTextBox" :label="{text: 'Address Line 2'}" />
              <dx-util-group-item :col-count="6">
                <dx-util-item data-field="city" :col-span="2" editor-type="dxTextBox" :label="{text: 'City'}">
                  <dx-util-required-rule message="City is required" />
                </dx-util-item>
                <dx-util-item data-field="zipcode" editor-type="dxTextBox" :label="{text: 'Zipcode'}">
                  <dx-util-required-rule message="Zipcode is required" />
                </dx-util-item>
                <dx-util-simple-item v-if="!isUSAAddress" data-field="state" :label="{ text: 'State'}" />
                <dx-util-simple-item v-else data-field="state" editor-type="dxSelectBox" :editor-options="stateOptions" :label="{ text: 'State'}">
                  <dx-util-required-rule message="State is required" />
                </dx-util-simple-item>
                <dx-util-item data-field="country" :col-span="2" editor-type="dxSelectBox" :editor-options="countryOptions" :label="{ text: 'Country'}">
                  <dx-util-required-rule message="Country is required" />
                </dx-util-item>
              </dx-util-group-item>
              <dx-util-item data-field="primary" :editor-options="primaryOptions" editor-type="dxCheckBox" :label="{text: 'Is Primary Address'}" />
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
      </div>

    </div>
  </div>

</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { countriesDetail, states } from '@/http/requests/statics/locations'
import commonEntityService from '@/http/requests/common/commonEntityService'
import warehouseService from '@/http/requests/warehouse/warehouseService'
import { Notify } from '@/@robustshell/utils'
import Pager from '@core/dev-extreme/utils/pager'

export default {
  components: {
    'warehouse-name-form': () => import('./WarehouseNameForm.vue'),
  },
  mixins: [GridBase],
  props: {
    companyName: {
      type: String,
      required: true,
    },
    commonHub: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      warehouses: [],
      warehouseForm: {
        id: '',
        commonHub: '',
        addressType: '',
        name: this.companyName,
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: 'USA',
        zipcode: '',
        primary: false,
      },
      addressTypes: [
        { text: 'Home', value: 0 },
        { text: 'Work', value: 1 },
      ],
    }
  },
  computed: {
    isUSAAddress() {
      return this.warehouseForm.country === 'USA'
    },
    warehouseFormPopup() {
      return this.$refs.addressFormPopupRef.instance
    },
    addressFormSheet() {
      return this.$refs.warehouseFormRef.instance
    },
    primaryOptions() {
      return {
        text: 'Primary',
      }
    },
    addressTypeOptions() {
      return {
        dataSource: this.addressTypes,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    stateOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: states,
        acceptCustomValue: false,
        inputAttr: {
          autocomplete: 'chrome-off',
        },
      }
    },
    countryOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: countriesDetail,
        valueExpr: 'code',
        displayExpr: 'name',
        inputAttr: {
          autocomplete: 'chrome-off',
        },
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.warehouseFormPopup.hide()
          this.addressFormSheet.resetValues()
          this.warehouseForm.addressType = 1
        },
      }
    },
  },
  mounted() {
    this.getWarehouses()
  },
  methods: {
    onShownAddressFormPopup() {
      this.warehouseForm.country = 'USA'
    },
    async getWarehouses() {
      const pager = new Pager({})
      const result = await warehouseService.fetchAll(pager.staticPageable)
      this.warehouses = result.data
    },
    openAddressForm(e) {
      const address = e.data.address
      this.warehouseForm.commonHub = address?.commonHub
      this.warehouseForm.id = address?.id
      this.warehouseForm.name = address?.name
      this.warehouseForm.line1 = address?.line1
      this.warehouseForm.line2 = address?.line2
      this.warehouseForm.city = address?.city
      this.warehouseForm.zipcode = address?.zipcode
      this.warehouseForm.state = address?.state
      this.warehouseForm.country = address?.country
      this.warehouseForm.primary = address?.primary
      const addressTypeValue = this.getAddressTypeValue(address?.addressType)
      this.warehouseForm.addressType = addressTypeValue
      this.warehouseFormPopup.show()
    },
    getAddressTypeValue(type) {
      return this.addressTypes.filter(el => el.text.toLowerCase() === type.toLowerCase())[0].value
    },
    async handleSubmit() {
      const validationResult = this.addressFormSheet.validate()
      if (validationResult.isValid) {
        if (this.warehouseForm.id) {
          await commonEntityService.updateAddress(this.warehouseForm)
          Notify.success('Warehouse is updated successfully.')
          this.getWarehouses()
          this.warehouseFormPopup.hide()
        }
      }
    },
    resetForm() {
      this.addressFormSheet.resetValues()
      this.warehouseForm.id = ''
      this.warehouseForm.addressType = 1
    },
    generateAddressString(address) {
      return `${address.line1 ? `${address.line1} ` : ''}${address.line2 ? address.line2 : ''}
      ${address.city ? address.city : ''}
      ${address.state ? `${address.state} ` : ''}${address.country ? address.country : ''} ${address.zipcode ? `${address.zipcode} ` : ''}
      `
    },
  },
}
</script>

<style lang="scss">
</style>
