import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get all WarehouseLocation records
  async fetchAll(pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.warehouse.warehouseController.findAll(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async updateName(warehouseId, name) {
    const result = await axios.get(endpoints.warehouse.warehouseController.updateName(warehouseId, name))
    return result
  },
}
